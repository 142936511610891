
// import './css/snap.css';
import { HomePage } from "./pages/HomePage";
import { LanguageProvider } from "./provider/LanguageProvider";

export default function App() {  
  return (
      <LanguageProvider>
        <HomePage />
      </LanguageProvider>
  )
}