
export const ImageSlide = ({image}) => {
    if(!image.classHelp)
        return <span className={`antialiased font-bold text-lg bg-gray-300 animate-pulse `}>
            &nbsp; Loading...
        </span>
    return (
        <div className="flex items-center align-middle justify-center -mt-16 ">
            <img className="object-fill h-screen w-screen" src={image.pc}alt={image.text}/>
            <div className="absolute text-white w-full text-4xl text-center font-gmr">
                {
                    image.logo?(
                        <div className=" flex w-full items-center justify-center -mt-20 ">
                            <img className="w-96 h-auto items-center"  src={image.logo} alt={image.text}/>
                        </div>
                    ):(
                        <h1 className={image.classText1}>{image.text}</h1>
                    )
                }                
                <h2 className={image.classText2}>{image.text1}</h2>
                <div className={image.classHelp}>
                    <h3 className={image.classText3}>{image.description}</h3>
                </div>                
            </div>
            {
                image.powered?(
                <div className="absolute flex top-3/4 h-1/6 w-full items-center " >
                    <h2 className={"text-sm text-quater font-gmr sm:text-lg md:text-md lg:text-xl xl:text-3xl text-left  ml-24 my-2 "}>{image.powerText}</h2>
                    <img className="w-12 h-12"  src={image.powered} alt={image.text}/>
                </div>):(<div></div>)
            }
        </div>
    );
};

    