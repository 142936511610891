import React, { useContext } from 'react'
import { LangContext } from '../context/langContext';
// import pc from '../images/Section6/pc.jpg';
// import ipad from '../images/Section6/ipad.jpg';
// import cel from '../images/Section6/cel.jpg';


export const Investors = () => {
    const [language] = useContext(LangContext);
  return (
    <div className="flex flex-col justify-between pt-16 h-full lg:min-h-screen">

        <div className='px-10 md:px-16 lg:px-24 mb-2 lg:mb-0'>
                <p className='text-quater font-gmr font-bold
                sm:text-lg md:text-2xl lg:text-2xl xl:text-4xl text-base'> { language.secc6lang1 } </p>
        </div>

        <div className='px-10 md:px-16 lg:px-24'>
                <p className='font-raleway text-senar text-justify xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.secc6lang2 } </p>
        </div>
        <div className='px-10 md:px-16 lg:px-24 mb-5 lg:mb-0 mt-2'>
                <p className='font-raleway text-senar text-justify xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.secc6lang3 } </p>
        </div>

        
        <div className="flex flex-col bg-cover bg-center h-1/5 lg:h-2/5 py-5 md:py-20 justify-evenly
            bg-[url('./images/Section6/cel.jpg')] md:bg-[url('./images/Section6/ipad.jpg')] lg:bg-[url('./images/Section6/pc.jpg')]">

            <div className='px-10 md:px-16 lg:px-24 mb-5'>
                    <p className='font-raleway text-white font-bold
                    xl:text-3xl lg:text-2xl md:text-2xl sm:text-xs text-xs drop-shadow-[0_3px_1px_rgba(0,0,0,1)]'> { language.secc6lang4 }</p>
            </div>
            
            <div className='px-10 md:px-16 lg:px-24 flex'>
                    <p className='font-raleway text-primary font-bold
                    xl:text-3xl lg:text-2xl md:text-2xl sm:text-xs text-xs drop-shadow-[0_3px_1px_rgba(0,0,0,1)]'> { language.secc6lang5 } </p>
            </div>

        </div>
    </div>
  )
}
