
import { useContext } from 'react';
import pc1 from '../images/Section4/pc1.jpg';
import c1 from '../images/Section4/c1.jpg';
import p1 from '../images/Section4/p1.jpg';
import skylink from '../images/Section4/skylink.png';
import { LangContext } from '../context/langContext';


export const Partners = () => {
  const [language] = useContext(LangContext);
  return (
    <>
        <div className="flex flex-col lg:flex-row h-full">
            <div className='lg:w-1/2'>
                <img src={pc1} className="h-full w-full object-cover hidden lg:block" alt=''/>
                <img src={c1} className="object-cover w-screen block md:hidden" alt=''/>
                <img src={p1} className="object-cover w-screen hidden md:block lg:hidden" alt=''/>
            </div>

            <div className="hidden lg:flex flex-col bg-cover bg-center bg-[url('./images/Section4/pc2.jpg')] justify-evenly w-1/2 py-16">
                <div className="px-10 md:px-16 lg:px-24">
                    <h1 className='font-gmr text-white sm:text-lg md:text-2xl lg:text-2xl xl:text-4xl text-base font-bold'>{ language.partners.title }</h1>
                </div>
                <div className="px-10 md:px-16 lg:px-24">
                        <img src={skylink} alt="International"  className="h-auto sm:mb-2 lg:w-24 xl:w-38 sm:w-32 w-24 -ml-1 mb-6" />                        
                        <div className="-mt-10 sm:-mt-7">
                            <p className='font-raleway text-white sm:indent-40 sm:-mr-2 indent-24 lg:indent-24 text-xs sm:text-xs lg:text-lg xl:text-xl text-justify' >{language.partners.paragraph12}</p>
                        </div>
                </div>
                <div className="px-10 md:px-16 lg:px-24">
                    <p className='font-raleway text-white text-xs sm:text-xs  lg:text-lg xl:text-xl text-justify'>{language.partners.paragraph13} </p>
                </div>

            </div>

            <div className="flex lg:hidden flex-col bg-cover bg-center py-10 md:py-20 h-full bg-[url('./images/Section4/c2.jpg')] md:bg-[url('./images/Section4/p2.jpg')] justify-evenly">
                <div className="px-10 md:px-16 lg:px-24">
                    <h1 className='font-gmr text-white sm:text-lg md:text-2xl lg:text-2xl xl:text-4xl text-base font-bold'>{ language.partners.title }</h1>
                </div>
                <div className="px-10 py-5 md:px-16 lg:px-24">
                        <img src={skylink} alt="International"  className="h-auto sm:mb-2 lg:w-24 xl:w-38 sm:w-32 w-24 -ml-1 mb-6" />                        
                        <div className="-mt-10 sm:-mt-7">
                            <p className='font-raleway text-white sm:indent-40 sm:-mr-2 indent-24 lg:indent-24 text-xs xs:text-2xs sm:text-sm md:text-base lg:text-base text-justify' >{language.partners.paragraph12}</p>

                        </div>
                </div>
                <div className="px-10 md:px-16 lg:px-24">
                    <p className='font-raleway text-white text-xs xs:text-2xs sm:text-sm md:text-base lg:text-base text-justify'>{language.partners.paragraph13} </p>
                </div>
            </div>


        </div>
    </>
  )
}