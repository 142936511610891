/* eslint-disable jsx-a11y/anchor-is-valid */


import imgtransfer from '../images/Section2/transfer.svg';
import imgcalls from '../images/Section2/calls.svg';
import imgcurency from '../images/Section2/currency.svg';
import imgpayment from '../images/Section2/payment.svg';

import { useContext, useState } from 'react';
import { LangContext } from '../context/langContext';

export const Products = () => {
    const [language] = useContext(LangContext);

    const [texto, setTexto] = useState(language.secc2text5)
    
    const cambiarText = ( boton ) => {
        switch( boton ){
            case 1:
                setTexto(language.secc2text5);
            break;
            case 2:
                setTexto(language.secc2text6);
            break;
            case 3:
                setTexto(language.secc2text7);
            break;
            case 4:
                setTexto(language.secc2text8);
            break;
            default:
                setTexto(language.secc2text5);
        }
    }
  return (
    <>
        <div className="flex flex-col px-10 md:px-16 lg:px-24 pt-16 h-full lg:min-h-screen justify-evenly">

            <div className='lg:-mb-6 md:mb-auto mb-8'>
                    <p className='text-quater font-gmr font-bold
                    text-xs xs:text-base sm:text-lg md:text-2xl lg:text-2xl xl:text-4xl '> { language.secc2text0 } </p>
            </div>

            <div className="flex flex-col lg:flex-row m-auto w-full text-white bg-quater
                        xl:p-2 md:p-10 px-8 py-3
                        xl:mt-20 lg:mt-10 md:mt-5 mt-0
                        lg:mb-3 mb-auto
                        lg:grid-cols-4 grid-cols-1 rounded-[48px]  
                        lg:divide-x-2 lg:divide-y-0 divide-y-2 divide-primary ">

                <div className="font-raleway w-full h-full flex flex-col justify-center text-center p-2
                     xl:text-lg lg:text-xs md:text-lg sm:text-xs text-xs"> 
                    <p>{ language.secc2text1 }</p>    
                    <p className='text-primary'>{ language.secc2text12 }</p>       
                    <p>{ language.secc2text13 }</p>       
                </div>  

                <div className="font-raleway w-full h-full flex flex-col justify-center text-center p-2
                    xl:text-lg lg:text-xs md:text-lg sm:text-xs text-xs"> 
                    <p>{ language.secc2text2 } </p>
                    <p className='text-primary'>{ language.secc2text22 } </p>
                </div>

                <div className="font-raleway w-full h-full flex flex-col justify-center text-center p-2
                    xl:text-lg lg:text-xs md:text-lg sm:text-xs text-xs"> 
                    <p>{ language.secc2text3 } </p>
                    <p className='text-primary'>{ language.secc2text32 } </p>
                </div>
                <div className="font-raleway w-full h-full flex flex-col justify-center text-center p-2
                    xl:text-lg lg:text-xs md:text-lg sm:text-xs text-xs"> 
                    <p>{ language.secc2text4 } </p>
                    <p className='text-primary'>{ language.secc2text42 } </p>
                </div>
            </div>

            <div className="flex flex-wrap lg:flex-nowrap lg:flex-row w-full mt-5 md:mt-0">

                <div className="font-gmr text-2xl w-1/2 h-full flex flex-col justify-center text-center items-center">
                    <img className="2xl:w-24 lg:w-16 md:w-20 w-14 lg:mb-8" src={imgtransfer} alt="Transfer"/>
                    <a onClick={ () => cambiarText(1) } className="font-ddin text-quina border-2 border-primary rounded-full 
                        md:p-2 p-1
                        xl:w-4/6 lg:w-4/6 md:w-5/6 w-4/6
                        xl:text-lg lg:text-xs md:text-base text-xs
                        hover:bg-second hover:text-white"> { language.transfer } </a>
                </div>
                
                <div className="font-raleway w-1/2 h-full flex flex-col justify-center text-center items-center">
                    <img className="2xl:w-24 lg:w-16 md:w-20 w-14 lg:mb-8" src={imgcalls} alt="Calls"/>
                    <a onClick={ () => cambiarText(2) } className="font-ddin text-quina border-2 border-primary rounded-full 
                        md:p-2 p-1
                        xl:w-4/6 lg:w-4/6 md:w-5/6 w-4/6  
                        xl:text-lg lg:text-xs md:text-base text-xs
                        hover:bg-second hover:text-white"> { language.calls } </a>
                </div>
                <div className="font-gmr text-2xl w-1/2 h-full flex flex-col justify-center text-center items-center "> 
                    <img className="2xl:w-24 lg:w-16 md:w-20 w-14 lg:mb-8" src={imgcurency} alt="Calls"/>
                    <a onClick={ () => cambiarText(3) } className="font-ddin text-quina border-2 border-primary rounded-full 
                        md:p-2 p-1
                        xl:w-4/6 lg:w-4/6 md:w-5/6 w-4/6  
                        xl:text-lg lg:text-xs md:text-base text-xs
                        hover:bg-second hover:text-white"> { language.currency } </a>
                </div>
                <div className="font-raleway w-1/2 h-full flex flex-col justify-center text-center items-center ">
                    <img className="2xl:w-24 lg:w-16 md:w-20 w-14 lg:mb-8" src={imgpayment} alt="Partner"/>
                    <a onClick={ () => cambiarText(4) } className="font-ddin text-quina border-2 border-primary rounded-full md:p-2 p-1 xl:w-4/6 lg:w-4/6 md:w-5/6 w-4/6 xl:text-lg lg:text-xs md:text-base text-xs hover:bg-second hover:text-white"> { language.payment } </a>
                </div>
            </div>

            <div className='font-raleway text-senar my-10
                sm:text-xs md:text-lg xl:text-xl lg:text-base text-xs
                flex-wrap text-justify mx-auto'> 
                {/* { language.secc2text5 }  */}
                { texto }
            </div>


        </div>
    </>
  )
}
