
import flagspain from '../images/countries/spain.png';
import flagusa from '../images/countries/usa.png';
import flaggermany from '../images/countries/germany.png';
import flagitaly from '../images/countries/italy.png';

export const LangChange = (country) => {
    const languages = [
        { id: 1, name: 'es', img: flagspain, lang: 'Español' },
        { id: 2, name: 'en', img: flagusa, lang: 'English' },
        { id: 3, name: 'de', img: flaggermany, lang: 'Deutsch' },
        { id: 4, name: 'it', img: flagitaly, lang: 'Italy' },
    ];
    if(country){
        localStorage.setItem("lang", country);
    }else{
        country = localStorage.getItem("lang")?localStorage.getItem("lang"):"es";
    }
    return {select: languages.filter(lan => lan.name===country)[0], langs: languages.filter(lan => lan.name!==country)}
}
