import React, { useEffect, useState } from 'react'
import { LangContext } from '../context/langContext';
import langes from '../lang/es.json';
import langen from '../lang/en.json';
import langde from '../lang/de.json';
import langit from '../lang/it.json';

export let lang='es';
export const LanguageProvider = ({ children }) => {

    const [language, setLanguage] = useState(() => {
        const langFromLocalStorage = localStorage.getItem("lang");
        return langFromLocalStorage ? langFromLocalStorage : 'es';
    });

    switch(language){
        case 'es':
            lang = langes;
        break;
        case 'en':
            lang = langen;
        break;
        case 'de':
            lang = langde;
        break;
        case 'it':
            lang = langit;
        break;
  
        default:
        lang = langes;
    }
  
    useEffect(() => {
      localStorage.setItem("lang", language);
    }, [language]);

  return (
    <LangContext.Provider value={[lang, setLanguage]}>
        { children }
    </LangContext.Provider>
  )
}
